<template lang='pug'>
v-card.outlined-card.my-2.px-2(
  :color='color'
  :style='styleObject'
  outlined
  tile
)
  slot
</template>

<script>
export default
{
  data()
  {
    return {
      styleObject:
      {
        'background-color': 'transparent!important',
        'border-width': 'medium!important',
        'border-top': 'none!important',
        'border-bottom': 'none!important',
        'border-right-color': 'transparent!important',
      }
    };
  },
  props:
  {
    color: { required: true }
  },
};
</script>