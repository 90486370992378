<template lang='pug'>
  div
    v-menu
      template(v-slot:activator='{ on }')
        v-btn(
          icon
          v-on='on'
        )
          v-icon mdi-menu-down
      v-list
        v-list-item(
          to='/create-community'
        )
          v-list-item-title Get suggestions
        v-list-item(
          :to="{ name: 'community', params: { idCustomCommunity: 'suggestiondrop' } }"
        )
          v-list-item-title About
</template>

<script>
import { defaultTo, get, pipe } from 'lodash/fp';

import { mapState } from 'vuex';

export default
{
  components:
  {
  },
  computed:
  {
    signInButtonLabel()
    {
      return pipe([
        get('currentUser.displayName'),
        defaultTo('Sign in'),
      ])(this);
    },
    ...mapState(['currentUser'])
  }
};
</script>
