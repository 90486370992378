<template lang='pug'>
CommunityId(
  :idCustomCommunity='idCustomCommunity'
  v-slot='{ id: idCommunity }'
)
  OutlinedCard(
    color='pink darken-1'
  )
    ReadCommunity(
      :idCommunity='idCommunity'
      v-slot='{ community, isOwner }'
    )
      vue-headful(
        :title='community.title'
      )
      Community(
        :idCommunity='idCommunity'
        :currentUser='currentUser'
        :idCustomCommunity='community?.idCustomCommunity'
        :description='community?.description'
        :numCampaigns='community?.numCampaigns'
        :title='community?.title'
        :uid='community?.uid'
        :url='community?.url'
      )
      CreateCampaign(
        v-if='isOwner'
        :idCommunity='idCommunity'
        :currentUser='currentUser'
        :idCustomCommunity='idCustomCommunity'
        @update:idCustomCampaign='submitted'
      )
      v-tabs(
        v-if='community.numCampaigns'
        background-color='transparent'
        color='grey'
        grow
        style='pointer-events:none'
      )
        v-tab Campaigns
    ReadCampaigns(
      :idCommunity='idCommunity'
      v-slot='{ campaigns }'
    )
      Campaign(
        v-for='campaign in campaigns'
        :key='campaign.key'
        :idCampaign='campaign.key'
        :idCommunity='idCommunity'
        :idCustomCommunity='idCustomCommunity'
        :currentUser='currentUser'
        :idCustomCampaign='campaign?.val?.idCustomCampaign'
        :description='campaign?.val?.description'
        :numSuggestions='campaign?.val?.numSuggestions'
        :title='campaign?.val?.title'
        :uid='campaign?.val?.uid'
        :url='campaign?.val?.url'
      )
</template>

<script>
import { mapState } from 'vuex';

import Campaign from '../components/Campaign';
import Community from '../components/Community';
import CreateCampaign from '../components/CreateCampaign';
import CommunityId from '../components/CommunityId';
import OutlinedCard from '../components/OutlinedCard';
import ReadCampaigns from '../components/ReadCampaigns';
import ReadCommunity from '../components/ReadCommunity';

const submitted = async function (idCustomCampaign)
{
  this.$router.push
  ({
    name: 'campaign',
    params:
    {
      idCustomCommunity: this.idCustomCommunity,
      idCustomCampaign,
    },
  });
};

export default
{
  components:
  {
    Campaign,
    Community,
    CreateCampaign,
    CommunityId,
    OutlinedCard,
    ReadCampaigns,
    ReadCommunity,
  },
  computed: mapState(['currentUser']),
  methods:
  {
    submitted,
  },
  props:
  {
    idCustomCommunity: { required: true }
  },
};
</script>