import { invoke, map, toPairs, zipObject } from 'lodash/fp'

import {
  getDatabase,
  ref,
  get,
} from 'firebase/database'

import {
  app,
} from '@thomasphan/vue-components/src/functions/initializeApp'

export const campaignsGet = idCommunity =>
(
  get
  (
    ref(getDatabase(app), `campaigns/${ idCommunity }`)
  )
  .then(invoke('val'))
  .then(toPairs)
  .then(map(zipObject(['key', 'val'])))
)