<template lang='pug'>
div
  slot(
    v-if='id'
    :id='id'
  )
</template>

<script>
import { communityGetId } from '../functions/communityGetId';

const mounted = async function ()
{
  this.id = await communityGetId
  (this?.idCustomCommunity);
};

export default
{
  data()
  {
    return {
      id: null,
    };
  },
  mounted,
  props:
  {
    idCustomCommunity: { required: true },
  },
};
</script>