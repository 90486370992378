<template lang='pug'>
.my-2
  FormCard(
    :titleInitial='titleInitial'
    :idCustomExisting='customCampaignIds'
    :idCustomInitial='idCustomInitial'
    :urlInitial='urlInitial'
    :descriptionInitial='descriptionInitial'
    :currentUser='currentUser'
    @update:result='updateResult'
  )
    template(
      v-slot:default
    )
      v-list-item(
        two-line
      )
        v-list-item-avatar(
          color='green'
        )
          v-icon(
            dark
          )
            | mdi-vote
        v-list-item-content
          v-list-item-title.headline
            | {{ titleInitial ? 'Edit' : 'Add' }} campaign
          v-list-item-subtitle
            | Signed in as #[CurrentUserDisplayName]
    template(
      v-slot:idCustomPreview='{ result }'
    )
      | suggestiondrop.com/community/{{ idCustomCommunity }}/campaign/{{ result?.idCustom }}
</template>

<script>
import CurrentUserDisplayName from './CurrentUserDisplayName';
import FormCard from './FormCard.vue';

import { campaignsGet } from '../functions/campaignsGet';

import { validatePath } from '../functions/validatePath';

import { assign, cond, identity, map, negate, pipe, set, unset } from 'lodash/fp';

const updateResult = function (result)
{
  const { idCustom: idCustomCampaign } = result;

  const campaign = pipe
  ([
    set('numSuggestions')(0),
    assign({ idCustomCampaign }),
    unset('idCustom'),
  ])
  (result);

  validatePath
  (
    `customCampaignIds/${ this.idCommunity }/${ idCustomCampaign }`
  )
  .then
  (
    cond
    ([
      [
        negate(identity),
        () => this.$emit('update:campaign', campaign),
      ],
      [
        () => idCustomCampaign === this?.idCustomInitial,
        () => this.$emit('update:campaign', campaign),
      ],
    ])
  );
};

const mounted = async function ()
{
  this.customCampaignIds = await campaignsGet(this?.idCommunity)
  .then
  (
    pipe
    ([
      map('val.idCustomCampaign'),
    ])
  );
};

export default
{
  components:
  {
    CurrentUserDisplayName,
    FormCard,
  },
  data()
  {
    return {
      customCampaignIds: [],
    };
  },
  methods:
  {
    updateResult,
  },
  mounted,
  props:
  {
    currentUser: { required: true },
    idCustomCommunity: { required: true },
    descriptionInitial: { required: true },
    idCommunity: { required: true },
    idCustomInitial: { required: true },
    titleInitial: { required: true },
    urlInitial: { required: true },
  },
};
</script>
