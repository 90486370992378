<template lang='pug'>
BaseDeleted(
  :isDeleted='isDeleted'
)
  router-link(
    :to="{ name: 'community', params: { idCustomCommunity } }"
    v-slot='{ isExactActive, route }'
    custom
  )
    v-card.my-2
      v-card-title(
        v-if='isExactActive'
      )
        | Community
      ListItemCommunity(
        :idCustomCommunity='idCustomCommunity'
        :title='title'
        :url='url'
      )
      v-card-text.body-1(
        style='white-space:pre-wrap'
      )
        | {{ description }} &nbsp;
      v-card-actions
        BaseDisabledButton(
          :to='route'
        )
          | {{ numCampaignsStr }}
        v-spacer
        DeleteCommunity(
          :idCommunity='idCommunity'
          :title='title'
          v-slot='{ confirmDelete }'
          @deleted='isDeleted = true'
        )
          v-menu(
            v-if='isOwner'
          )
            template(
              v-slot:activator='{ on }'
            )
              v-btn(
                icon
                v-on='on'
              )
                v-icon
                  | mdi-dots-vertical
            v-list
              v-list-item(
                v-if='isExactActive'
                :to="{ name: 'updateCommunity', params: { idCustomCommunity } }"
              )
                v-list-item-title
                  | Edit
              v-list-item(
                v-if='$route.name === "createCommunity" && !numCampaigns'
                @click='confirmDelete'
              )
                v-list-item-title
                  | Delete
              v-list-item(
                v-if='$route.name === "createCommunity" && numCampaigns'
                @click='alert("One or more campaigns are linked to this community")'
              )
                v-list-item-title
                  | Delete
</template>

<script>
import ListItemCommunity from '../components/ListItemCommunity';
import BaseDeleted from '../components/BaseDeleted';
import DeleteCommunity from '../components/DeleteCommunity';
import BaseDisabledButton from '../components/BaseDisabledButton';

import { defaultTo, get } from 'lodash/fp';

const alert = function (msg)
{
  window.alert(msg);
};

const isOwner = function ()
{
  return this?.uid === this?.currentUser?.uid;
};

const numCampaignsStr = function ()
{
  return `Campaigns ${ this.numCampaigns }`;
};

export default
{
  components:
  {
    ListItemCommunity,
    BaseDeleted,
    DeleteCommunity,
    BaseDisabledButton,
  },
  computed:
  {
    isOwner,
    numCampaignsStr,
  },
  data()
  {
    return {
      isDeleted: false,
    };
  },
  props:
  {
    idCommunity: { required: true },
    currentUser: { required: false },
    idCustomCommunity: { required: true },
    description: { required: false },
    numCampaigns: { required: true },
    title: { required: true },
    uid: { required: true },
    url: { required: true },
  },
  methods:
  {
    alert,
    defaultTo,
    get,
  },
};
</script>
