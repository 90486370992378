<template lang='pug'>
div
  slot(
    :confirmDelete='confirmDelete'
  )
</template>

<script>
import { cond, wrap } from 'lodash/fp';

import { campaignDelete } from '../functions/campaignDelete';

const remove = async function ()
{
  await campaignDelete
  (this?.idCommunity)
  (this?.idCampaign);

  this.$emit('deleted');
};

const confirmDelete = function ()
{
  cond
  ([[
    wrap
    (confirm)
    (`Are you sure you want to delete "${ this.title }"?`),
    () => this.remove()
  ]])
  ();
};

export default
{
  methods:
  {
    confirmDelete,
    remove,
  },
  props:
  {
    idCampaign: { required: true },
    idCommunity: { required: true },
    title: { required: true },
  },
};
</script>
