<template lang='pug'>
FormCampaign(
  v-if='currentUser'
  :idCommunity='idCommunity'
  :currentUser='currentUser'
  :idCustomCommunity='idCustomCommunity'
  :idCustomInitial='null'
  :descriptionInitial='null'
  :titleInitial='null'
  :urlInitial='null'
  @update:campaign='createCampaign'
)
</template>

<script>
import FormCampaign from '../components/FormCampaign';

import { campaignCreate } from '../functions/campaignCreate';

import { waitForSnapshot } from '../functions/waitForSnapshot';

const createCampaign = async function (campaign)
{
  await campaignCreate(this.idCommunity)(campaign);

  await waitForSnapshot
  (
    `customCampaignIds/${ this.idCommunity }/${ campaign.idCustomCampaign }`
  );

  this.$emit('update:idCustomCampaign', campaign?.idCustomCampaign);
};

export default
{
  components:
  {
    FormCampaign,
  },
  methods:
  {
    createCampaign,
  },
  props:
  {
    idCommunity: { required: true },
    currentUser: { required: true },
    idCustomCommunity: { required: true },
  },
};
</script>
