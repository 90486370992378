<template lang='pug'>
div
  v-chip(
    label
  ) Featured Community
  Community(
    idCustomCommunity='tesla'
  )
</template>

<script>
import Community from '../views/Community';

export default
{
  components:
  {
    Community
  }
};
</script>