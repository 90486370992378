<template lang='pug'>
v-card
  slot
  v-card-text
    v-form(
      @submit.prevent=''
      ref='form'
      v-model='valid'
    )
      label(
        :for='id.title'
      )
        | Title
      v-text-field(
        flat
        light
        solo
        type='text'
        v-model='title'
        :id='id.title'
        :rules='titleRules'
      )
      label(
        :for='id.idCustom'
      )
        | Custom ID&nbsp;-&nbsp;
        slot(
          name='idCustomPreview'
          :result='result'
        )
      v-text-field(
        flat
        light
        solo
        type='text'
        v-model='idCustom'
        :id='id.idCustom'
        :rules='idCustomRules'
        @focus='idCustom = idCustom || kebabCase(titleSanitized)'
      )
      label(
        :for='id.url'
      )
        | URL
      v-text-field(
        flat
        light
        solo
        type='text'
        v-model='url'
        :id='id.url'
        :rules='urlRules'
      )
      label(
        :for='id.description'
      )
        | Description
      v-textarea(
        flat
        light
        solo
        v-model='description'
        :id='id.description'
      )
  v-card-actions
    v-btn(
      @click='submit'
      :disabled='!valid'
      :loading='isLoading'
      color="success"
      block
    )
      | Submit
</template>

<script>
import { cond, defaultTo, identity, includes, kebabCase, pipe, replace, stubTrue, trim, uniqueId } from 'lodash/fp';

import {
  serverTimestamp,
} from 'firebase/database';

const submit = function ()
{
  this.isLoading = true;

  this.$emit('update:result', this?.result);
};

const result = function ()
{
  const title = this?.titleSanitized;
  const idCustom = this?.idCustomSanitized;
  const url = this?.urlSanitized;
  const description = this?.descriptionSanitized;

  const timestamp = serverTimestamp();
  const uid = this?.currentUser?.uid;

  return pipe
  ([
    window.JSON.stringify,
    window.JSON.parse,
  ])
  ({
    title,
    idCustom,
    url,
    description,
    timestamp,
    uid,
  });
};

const descriptionRules = function ()
{
  return [
    () => !!this?.descriptionSanitized || 'Required'
  ];
};

const descriptionSanitized = function ()
{
  return pipe
  ([
    trim,
  ])
  (this?.description);
};

const idCustomRules = function ()
{
  return [
    () => !!this?.idCustomSanitized || 'Required',
    () => includes(this?.idCustomSanitized)(this?.idCustomExisting) && this?.idCustomSanitized !== this?.idCustomInitial ? 'Not available' : true,
  ];
};

const idCustomSanitized = function ()
{
  return kebabCase(this?.idCustom);
};

const titleRules = function ()
{
  return [
    () => !!this?.titleSanitized || 'Required'
  ];
};

const titleSanitized = function ()
{
  return pipe
  ([
    trim,
  ])
  (this?.title);
};

const urlRules = function ()
{
  return [
    () =>
    (
      pipe
      ([
        replace(/^https:\/\//i)(''),
        cond([[identity, stubTrue]]),
        defaultTo('Required'),
      ])
      (this?.urlSanitized)
    )
  ];
};

const urlSanitized = function ()
{
  return pipe
  ([
    trim,
  ])
  (this?.url);
};

const mounted = async function ()
{
  this?.$refs?.form?.validate?.();
};

const id = function ()
{
  return {
    title : `title${ this.idUnique }`,
    idCustom : `idCustom${ this.idUnique }`,
    url : `url${ this.idUnique }`,
    description : `description${ this.idUnique }`,
  };
};

export default
{
  computed:
  {
    titleRules,
    titleSanitized,
    idCustomRules,
    idCustomSanitized,
    urlRules,
    urlSanitized,
    descriptionRules,
    descriptionSanitized,
    result,
    id,
  },
  data()
  {
    return {
      title: this.titleInitial,
      idCustom: this.idCustomInitial,
      url: this.urlInitial || 'https://',
      description: this.descriptionInitial,
      isLoading: false,
      valid: false,
      idUnique: uniqueId(),
    };
  },
  methods:
  {
    kebabCase,
    submit,
  },
  mounted,
  props:
  {
    titleInitial: { required: true },
    idCustomExisting: { required: true },
    idCustomInitial: { required: true },
    urlInitial: { required: true },
    descriptionInitial: { required: true },
    currentUser: { required: true },
  },
};
</script>