<template lang='pug'>
BaseDeleted(
  :isDeleted='isDeleted'
)
  router-link(
    :to="{ name: 'campaign', params: { idCustomCampaign, idCustomCommunity } }"
    v-slot='{ isExactActive, route }'
    custom
  )
    v-card.my-2
      v-card-title(
        v-if='isExactActive'
      )
        | Campaign
      ListItemCampaign(
        :idCustomCampaign='idCustomCampaign'
        :idCustomCommunity='idCustomCommunity'
        :title='title'
        :url='url'
      )
      v-card-text.body-1(
        style='white-space:pre-wrap'
      )
        | {{ description }} &nbsp;
      v-card-actions
        BaseDisabledButton(
          :to='route'
        )
          | {{ numSuggestionsStr }}
        v-spacer
        DeleteCampaign(
          :idCampaign='idCampaign'
          :idCommunity='idCommunity'
          :title='title'
          v-slot='{ confirmDelete }'
          @deleted='isDeleted = true'
        )
          v-menu(
            v-if='isOwner && (isExactActive || $route.name === "community")'
          )
            template(
              v-slot:activator='{ on }'
            )
              v-btn(
                icon
                v-on='on'
              )
                v-icon
                  | mdi-dots-vertical
            v-list
              v-list-item(
                v-if='isExactActive'
                :to="{ name: 'campaignAdmin' }"
              )
                v-list-item-title
                  | Edit
              v-list-item(
                v-if='$route.name === "community" && !numSuggestions'
                @click='confirmDelete'
              )
                v-list-item-title
                  | Delete
              v-list-item(
                v-if='$route.name === "community" && numSuggestions'
                @click='alert("One or more suggestions are linked to this campaign")'
              )
                v-list-item-title
                  | Delete
</template>

<script>
import BaseDeleted from '../components/BaseDeleted';
import BaseDisabledButton from '../components/BaseDisabledButton';
import DeleteCampaign from '../components/DeleteCampaign';
import ListItemCampaign from '../components/ListItemCampaign';

import { defaultTo, get } from 'lodash/fp';

const isOwner = function ()
{
  return this?.currentUser?.uid === this?.uid;
};

const numSuggestionsStr = function ()
{
  return `Suggestions ${ this.numSuggestions }`;
};

const alert = function (msg)
{
  window.alert(msg);
};

export default
{
  components:
  {
    BaseDeleted,
    BaseDisabledButton,
    DeleteCampaign,
    ListItemCampaign,
  },
  computed:
  {
    isOwner,
    numSuggestionsStr,
  },
  data()
  {
    return {
      isDeleted: false,
    };
  },
  methods:
  {
    alert,
    defaultTo,
    get,
  },
  props:
  {
    idCampaign: { required: true },
    idCommunity: { required: true },
    idCustomCampaign: { required: true },
    idCustomCommunity: { required: true },
    currentUser: { required: false },
    description: { required: false },
    numSuggestions: { required: true },
    title: { required: true },
    uid: { required: true },
    url: { required: true },
  },
};
</script>
