<template lang='pug'>
span
  slot(
    :displayName='displayName'
  )
    | {{ displayName }}
</template>

<script>
import { cond, get, pipe, stubTrue } from 'lodash/fp';

import { mapState } from 'vuex';

const displayName = function ()
{
  return pipe
  ([
    cond
    ([
      [get('isAnonymous'), () => 'Guest'],
      [get('displayName'), get('displayName')],
      [get('email'), get('email')],
      [stubTrue, () => '...'],
    ]),
  ])
  (this?.currentUser);
};

export default
{
  computed:
  {
    displayName,
    ...mapState(['currentUser']),
  }
};
</script>
