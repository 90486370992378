<template lang='pug'>
router-link(
  :to='to'
  v-slot='{ isExactActive, route }'
  custom
)
  v-btn(
    exact
    text
    :disabled='isExactActive'
    :to='isExactActive ? null : route'
  )
    span(
      :class='classObject'
    )
      slot
</template>

<script>
export default
{
  computed:
  {
    classObject()
    {
      return {
        'white--text': this.$vuetify.theme.dark,
        'grey--text': !this.$vuetify.theme.dark,
        'text--darken-4': !this.$vuetify.theme.dark,
      };
    }
  },
  props:
  {
    to: { required: true }
  },
};
</script>
