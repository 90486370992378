import {
  getDatabase,
  ref,
  remove,
} from 'firebase/database'

import {
  app,
} from '@thomasphan/vue-components/src/functions/initializeApp'

export const communityDelete = idCommunity =>
(
  remove
  (
    ref(getDatabase(app), `communities/${ idCommunity }`)
  )
)