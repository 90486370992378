<template lang='pug'>
  v-app
    v-app-bar(
      app
    )
      v-btn(
        text
        to='/'
      )
        | Suggestion&#129300;Drop
      v-spacer
      v-btn.hidden-xs-only.ml-2.hidden-xs-only(
        text
        to='/create-community'
      )
        //- | Get suggestions
        | Unlock growth potential
      v-btn.hidden-xs-only.ml-2.hidden-xs-only(
        text
        :to="{ name: 'community', params: { idCustomCommunity: 'suggestiondrop' } }"
      )
        | About
      TheMainMenu.ml-2.hidden-sm-and-up
      TheUserIcon
    v-main
      Uid(
        @onAuthStateChanged='currentUser => $store.dispatch({ type: "setCurrentUser", currentUser })'
      )
        v-container
          v-row(justify='center' no-gutters)
            v-col(
              sm='10'
              md='8'
              lg='6'
              xl='4'
            )
              router-view
              p.caption &copy; {{ year }} Suggestion Drop &bull; #[router-link(:to="{ name: 'campaign', params: { idCustomCampaign: 'feedback', idCustomCommunity: 'suggestiondrop' } }") Feedback]
</template>

<script>
import TheMainMenu from './components/TheMainMenu';

import TheUserIcon from '@thomasphan/vue-components/src/components/TheUserIcon.vue';
import Uid from '@thomasphan/vue-components/src/components/Uid.vue';

export default {
  name: 'App',
  components: {
    TheMainMenu,
    TheUserIcon,
    Uid,
  },
  data() {
    return {
      year: (new Date()).getFullYear()
    };
  },
};
</script>