<template lang='pug'>
div
  slot(
    v-if='campaigns'
    :campaigns='campaigns'
  )
</template>

<script>
import { campaignsGet } from '../functions/campaignsGet';

const mounted = async function ()
{
  this.campaigns = await campaignsGet(this.idCommunity);

  this.$emit('campaigns', this.campaigns);
};

export default
{
  data()
  {
    return {
      campaigns: null,
    };
  },
  mounted,
  props:
  {
    idCommunity: { required: true }
  },
};
</script>
