import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    shouldShowAuthDialog: false,
  },
  mutations: {
    setCurrentUser (state, { currentUser }) {
      state.currentUser = currentUser;
    },
    showAuthDialog (state) {
      state.shouldShowAuthDialog = true;
    },
    hideAuthDialog (state) {
      state.shouldShowAuthDialog = false;
    },
  },
  actions: {
    setCurrentUser ({ commit }, { currentUser }) {
      commit({ type: 'setCurrentUser', currentUser });
    },
    showAuthDialog ({ commit }) {
      commit({ type: 'showAuthDialog' });
    },
    hideAuthDialog ({ commit }) {
      commit({ type: 'hideAuthDialog' });
    },
  }
})
