import { invoke } from 'lodash/fp'

import {
  getDatabase,
  ref,
  get,
} from 'firebase/database'

import {
  app,
} from '@thomasphan/vue-components/src/functions/initializeApp'

export const validatePath = path =>
(
  get
  (
    ref(getDatabase(app), path)
  )
  .then(invoke('exists'))
)