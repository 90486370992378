import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/create-community',
      name: 'createCommunity',
      component: () => import(/* webpackChunkName: "createCommunity" */ './views/CreateCommunity.vue'),
      props: true
    },
    {
      path: '/community/:idCustomCommunity',
      name: 'community',
      component: () => import(/* webpackChunkName: "community" */ './views/Community.vue'),
      props: true
    },
    {
      path: '/community/:idCustomCommunity/update',
      name: 'updateCommunity',
      component: () => import(/* webpackChunkName: "updateCommunity" */ './views/UpdateCommunity.vue'),
      props: true
    },
    {
      path: '/community/:idCustomCommunity/campaign/:idCustomCampaign',
      name: 'campaign',
      component: () => import(/* webpackChunkName: "campaign" */ './views/Campaign.vue'),
      props: true
    },
    {
      path: '/community/:idCustomCommunity/campaign/:idCustomCampaign/admin',
      name: 'campaignAdmin',
      component: () => import(/* webpackChunkName: "campaignAdmin" */ './views/CampaignAdmin.vue'),
      props: true
    },
    {
      path: '/community/:idCustomCommunity/campaign/:idCustomCampaign/suggestion/:suggestionId',
      name: 'suggestion',
      component: () => import(/* webpackChunkName: "suggestion" */ './views/Suggestion.vue'),
      props: true
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/signin',
      name: 'signin',
      component: () => import(/* webpackChunkName: "signIn" */ './views/SignIn.vue')
    }
  ]
})
