<template lang='pug'>
div(
  :style='style'
)
  slot
</template>

<script>
export default
{
  computed:
  {
    style()
    {
      return this?.isDeleted ? { pointerEvents: 'none', opacity: .5 } : {};
    }
  },
  props:
  {
    isDeleted: { required: true }
  }
};
</script>
