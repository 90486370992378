<template lang='pug'>
div
  slot(
    v-if='community'
    :community='community'
    :isOwner='isOwner'
  )
</template>

<script>
import { mapState } from 'vuex';

import { communityGet } from '../functions/communityGet';

const mounted = async function ()
{
  this.community = await communityGet(this.idCommunity);

  this.$emit('community', this.community);
};

const isOwner = function ()
{
  return this?.currentUser?.uid === this?.community?.uid;
};

export default
{
  computed:
  {
    isOwner,
    ...mapState(['currentUser']),
  },
  data()
  {
    return {
      community: null
    };
  },
  mounted,
  props:
  {
    idCommunity: { required: true }
  },
};
</script>
