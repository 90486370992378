<template lang='pug'>
router-link(
  :to="{ name: 'community', params: { idCustomCommunity } }"
  v-slot='{ isExactActive, route }'
  custom
)
  v-list-item(
    exact
    two-line
    :inactive='isExactActive'
    :ripple='!isExactActive'
    :to='route'
  )
    v-list-item-avatar(
      color='pink'
    )
      v-icon(
        dark
      )
        | mdi-account-multiple
    v-list-item-content
      v-list-item-title(
        :class='{ headline: false }'
      )
        | {{ title }}
      v-list-item-subtitle
        | {{ url }} &nbsp;
    v-list-item-action
      v-btn(
        icon
        target='_blank'
        :href='url'
        @click.stop
      )
        v-icon
          | mdi-link
</template>

<script>
export default
{
  props:
  {
    idCustomCommunity: { required: true },
    title: { required: true },
    url: { required: true },
  },
};
</script>
