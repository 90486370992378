import { cond, invoke, pipe, tap } from 'lodash/fp'

import {
  getDatabase,
  ref,
  onValue,
} from 'firebase/database'

import {
  app,
} from '@thomasphan/vue-components/src/functions/initializeApp'

export const waitForSnapshot = path =>
(
  new Promise
  (
    resolve =>
    {
      const off = onValue
      (
        ref(getDatabase(app), path),
        cond
        ([[
          invoke('exists'),
          pipe
          ([
            invoke('val'),
            tap(resolve),
            tap(() => off()),
          ]),
        ]]),
      );
    }
  )
)