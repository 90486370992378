import {
  getDatabase,
  ref,
  push,
} from 'firebase/database'

import {
  app,
} from '@thomasphan/vue-components/src/functions/initializeApp'

export const campaignCreate = idCommunity => campaign =>
(
  push
  (
    ref(getDatabase(app), `campaigns/${ idCommunity }`),
    campaign,
  )
)